/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// import { useStaticQuery, graphql } from "gatsby";

import "./layout.css";

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `);

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          paddingTop: 0,
          position: "relative",
        }}
      >
        <main>{children}</main>
        <footer
          style={{
            position: "absolute",
            zIndex: 9,
            bottom: 5,
            left: 0,
            right: 0,
          }}
        >
          <div style={{ marginBottom: -3 }}>
            <FooterLink href="/terms" style={{ marginRight: 4 }}>
              Terms
            </FooterLink>{" "}
            <FooterLink href="/privacy" style={{ marginRight: 4 }}>
              Privacy
            </FooterLink>
            <FooterLink href="/security">Security</FooterLink>
          </div>
          <div>© {new Date().getFullYear()}</div>
        </footer>
      </div>
    </>
  );
};

const FooterLink = styled.a`
  border-bottom: 2px solid;
  border-color: #dee2e6;
  padding-bottom: 2px;
  transition: all 0.2s ease-in-out;
  color: #343a40;
  text-decoration: none;

  &:hover {
    color: #121416;
    border-color: #adb5bd;
    text-decoration: none;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
